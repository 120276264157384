.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    background:radial-gradient(#74b9ff, #0984e3);
    height: 100vh;
}

.loader > span{
    font-family: Consolas, Menlo, Monaco, monospace;
    font-weight: bold;
    font-size:30vh;
    opacity: 0.8;
    display: inline-block;
    color: #fff;
    animation: pulse 0.4s alternate infinite ease-in-out;

    &:nth-child(odd) {
      animation-delay: 0.4s;
    }
  }

@keyframes pulse {
  to {
    transform: scale(0.8);
    opacity: 0.5;
  }
}