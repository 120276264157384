footer{
    background-color:#2D3436;
    color: white;
    bottom: 0;
    margin-top: auto;
}

.links-container{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.information{
    padding-left: 1rem;
    display: flex;
    flex-direction: row;
    font-size: 1.2rem;
}

.footer-icon{
    padding-right: 2rem;
}

.links-container > a{
    padding: 1.2rem;
    margin-left: 50%;
   color: white;
    margin: 0;
    text-decoration: none;
    span{
      font-weight: bold;
    }
}
.footer-icon-a{
    position: absolute;
    right: 0;
    text-decoration: none;
    color: white;
 }



