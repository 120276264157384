html {
  scroll-behavior: smooth;
}
h2{
  font-family: 'Secular One', sans-serif;
}
p{
  font-family: 'Questrial', sans-serif;
}
a{
  font-family: 'Questrial', sans-serif;
}
button{
  font-family: 'Archivo Black', sans-serif;
}
body {
  font-size: 1rem;
}
.loadaing{
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background:radial-gradient(#9b59b6, #8e44ad);
}
@font-face {
  font-family: logoText;
  src: url('../../../public//Bonus\ Coffee.ttf');
}
.logoText{
  font-family: logoText;
}
@media screen and (min-width: 720px) {
  body {
    font-size: 1.6rem;
  }
}
