.nav-style{
    position: relative;
    width: 100%;
    max-width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: #0984e3;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-between;
}
.nav-style > a{
    color: white;
    font-weight: 400;
    padding-left: 1rem;
    margin: 0;
    text-decoration: none;
    span{
      font-weight: bold;
    }
}
.nav-links{
    font-size: 2.5rem;
    font-weight: bold;
    color: white;
    text-decoration: none;
    margin-right: 1.5rem;
    transition: 0.3s ease-in-out;
}
.links{
    display: none;
    text-align: center;
}
.links.active{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: absolute;
    margin:0;
    top:100%;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #74b9ff;
    z-index: 99;
}
.nav-container{
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.search-bar{
    display: contents;
    margin-right: 1rem;
}
.non-display{
    display: none;
}

@media(min-width: 768px){
  .burguer{
    display: none;
}
}
